<template>
    <el-dialog :close-on-click-modal="false"
               title="公司账户管理"
               :visible="true"
               width="500px"
               append-to-body
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-form-item label="账户-开户人"
                          prop="accountHolder">
                <el-input v-model="form.accountHolder"
                          placeholder="请输入开户人"
                          clearable />
            </el-form-item>
            <el-form-item label="账户"
                          prop="account">
                <el-input v-model="form.account"
                          placeholder="请输入账户"
                          clearable />
            </el-form-item>
            <el-form-item label="账户-开户行"
                          prop="accountBank">
                <el-input v-model="form.accountBank"
                          placeholder="请输入开户行"
                          clearable />
            </el-form-item>
            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popAddCompanyAccount",
    props: {},
    watch: {},
    data() {
        return {
            form: {
                account: "",
                accountBank: "", // 确认密码
                accountHolder: "",
            },
            isloading: false,
            rules: {
                accountHolder: [
                    {
                        required: true,
                        message: "请输入开户人",
                    },
                ],
                account: [
                    {
                        required: true,
                        message: "请输入账户",
                    },
                ],
                accountBank: [
                    {
                        required: true,
                        message: "请输入开户行",
                    },
                ],
            },
        };
    },
    mounted() {
        //查看账户是否已经缓存
        this.getCompany();
    },
    methods: {
        //查询公司账户
        getCompany() {
            // commonAPI
            //     .getCompanyAccount()
            //     .then((res) => {
            //         this.form = res.content;
            //     });
            this.$store.dispatch("getCompamyAccount").then((res) => {
                this.form = res;
            });
        },
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        //修改公司账户信息
        save() {
            this.isloading = true;
            commonAPI
                .saveCompanyAccount(this.form)
                .then((v) => {
                    this.$message({type:"success",message:v.message || "操作成功"})
                    this.isloading = false;
                    this.$store.commit("setCompanyAccount", this.form); //更新账户信息
                    this.$emit("close");
                })
                .catch(() => (this.isloading = false));
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
};
</script>
<style scoped>
</style>