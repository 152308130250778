// 供应商信息管理
<template>

    <div class="baseBg reserveProject">
        <div class="listBox mainWhiteBg">
            <el-tabs v-model="activeName">

                <el-tab-pane :name="item.contractStatus"
                             v-for="(item,index) in tabList"
                             :key="index">
                    <div class="tabsLabel"
                         slot="label">
                        <el-badge :type="activeName == item.contractStatus ? 'primary' : 'warning'"
                                  :value="item.value"
                                  class="item">
                            {{item.title}}
                        </el-badge>
                    </div>
                    <contractManageList :contractStatus="item.contractStatus"
                                        :detailRole="detailRole"
                                        @contractTypeNum="setContractTypeNum"
                                        :type="item.type"></contractManageList>
                </el-tab-pane>

            </el-tabs>
        </div>
    </div>

</template>

<script>
// import baseinfoManage from "@/api/baseinfoManage.js";
// import Pagination from "@/components/Pagination"; // 分页
import contractManageList from "./list";
export default {
    name: "contractManage",
    props: [],

    components: {
        // Pagination,
        contractManageList,
    },

    data() {
        return {
            activeName: "",
            dialogVisible: false,
            detailRole: {}, //权限数据
            tabList:[],
            tabListData: [
                {
                    contractStatus: "START",
                    type: "begin",
                    value: "0",
                    title: "初拟",
                    roleTab:"begin_tab"
                },
                {
                    contractStatus: "EXECUTING",
                    type: "doing",
                    value: "0",
                    title: "执行中",
                    roleTab:"doing_tab"
                },
                {
                    contractStatus: "DISTRIBUTION",
                    type: "undo",
                    value: "0",
                    title: "待分配",
                    roleTab:"undo_tab"
                },
            ], //合同状态tab
        };
    },

    created() {},

    mounted() {
        // if (window.localStorage.getItem("contractManageTab")) {
        //     this.activeName = window.localStorage.getItem("contractManageTab");
        //     window.localStorage.removeItem("contractManageTab");
        // }
    },

    methods: {
        //设置各类型合同数量
        setContractTypeNum(data) {
            this.tabList = this.tabList.map((v) => {
                if (v.contractStatus === data.contractStatus) {
                    v.value = data.num;
                }
                return v;
            });
        },
    },

    computed: {
        //权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        // 切换tab就清空
        activeName() {
            // if (window.localStorage.getItem("contractManageTab")) {
            //     window.localStorage.removeItem("contractManageTab");
            // }
        },

        //角色按钮权限
        roleInfo(val) {
            if(!val){return}
            this.detailRole = this.commonJs.tabIsAlive(
                val,
                "合同管理_列表"
            );
            let tabList = [];
            /* tab 过滤  根据按钮权限配置过滤 */
            this.tabListData.forEach(v => {
                this.detailRole.buttonMenus.forEach(item=>{
                    if(item.title === v.roleTab && item.checked === true){
                        tabList.push(v);
                    }
                })
            });
            this.tabList = tabList;
            /* 遍历当前tab是否存在当前权限列表中 */
            if(this.tabList.filter(v=>v.contractStatus === this.activeName).length === 0){this.activeName = this.tabList[0] ? this.tabList[0].contractStatus : ""}
        },
    },
};
</script>

<style lang='scss' scoped>
.reserveProject ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProject ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
</style>
