// 供应商信息管理
<template>
    <div class="">
        <div class="ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll fullRow"
                     :inline="true"
                     size="normal">
                <el-form-item label="合同名称">
                    <el-input class="width150"
                              v-model="searchForm.name"
                              placeholder="请输入合同名称"
                              clearable />
                </el-form-item>

                <el-form-item label="项目客户">
                    <selectTree placeholderStr="请选择客户/供应商"
                                :showSeeProvider="false"
                                :disable="true"
                                :shouldClean="true"
                                :isScorll="true"
                                @handClear="clearProvider"
                                :cleanProvider="cleanProvider"
                                @treeSelectNodeClick="customerClick"></selectTree>
                </el-form-item>

                <el-form-item label="合同类型">
                    <el-select class="width150"
                               v-model="searchForm.type"
                               placeholder="请选择合同类型">
                        <el-option v-for="item in options"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="部门">
                    <deptSelectTree class="width150"
                                    placeholderStr="请选择部门"
                                    :needClean="needClean"
                                    :shouldClean="true"
                                    @handClear="clearDept"
                                    @treeSelectNodeClick="departmentClick"></deptSelectTree>
                </el-form-item>

                <el-form-item v-if="type==='doing'"
                              label="非质保金收款情况">
                    <el-select class="width150"
                               v-model="searchForm.normalIsFinish"
                               placeholder="请选择">
                        <el-option v-for="item in quality"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"
                               @click="search">搜索</el-button>
                </el-form-item>
                <!-- <el-form-item v-role="commonJs.getBtnRoles(detailRole,'search2')">
                    <el-button type="success"
                               @click="search">搜索</el-button>
                </el-form-item> -->

                <el-form-item>
                    <el-button type=""
                               @click="celanSearch">清除</el-button>
                </el-form-item>

                <div class="flr">
                    <el-button type="primary"
                               v-role="commonJs.getBtnRoles(detailRole,'add_companyAccount')"
                               v-if="type==='begin'"
                               @click="openCompanyAccount">公司账户管理</el-button>
                    <el-button type="success"
                               v-role="commonJs.getBtnRoles(detailRole,'add_contract')"
                               v-loading="iscreating"
                               v-if="type==='begin'"
                               @click="addNew">新增合同</el-button>
                </div>
            </el-form>

        </div>

        <div class="listBox pt-5">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          v-loading="isloading"
                          :height="tableHeight"
                          stripe>
                    <el-table-column type="index"
                                     width="50"
                                     label="序号">
                    </el-table-column>
                    <el-table-column prop="code"
                                     width="200"
                                     label="合同编号">
                    </el-table-column>
                    <el-table-column prop="name"
                                     width="200"
                                     label="合同名称">
                    </el-table-column>
                    <el-table-column prop="companyName"
                                     width="200"
                                     label="客户/供应商">
                    </el-table-column>
                    <el-table-column prop="type.text"
                                     width="100"
                                     align="center"
                                     label="合同类型">
                    </el-table-column>
                    <el-table-column v-if="type==='undo'"
                                     prop="payTotalMoney"
                                     width="80"
                                     align="center"
                                     label="收款金额（元）">
                    </el-table-column>
                    <el-table-column v-if="type==='undo'"
                                     prop="waitMoney"
                                     width="100"
                                     align="center"
                                     label="待分配金额（元）">
                    </el-table-column>
                    <el-table-column v-if="type==='begin'"
                                     prop="totalMoney"
                                     width="80"
                                     align="center"
                                     label="合同金额（元）">
                    </el-table-column>
                    <el-table-column v-if="type==='doing'"
                                     prop="totalMoney"
                                     width="80"
                                     align="center"
                                     label="签订金额（元）">
                    </el-table-column>
                    <el-table-column v-if="type==='doing'"
                                     prop="remainMoney"
                                     width="120"
                                     align="center"
                                     label="剩余收付款金额（元）">
                    </el-table-column>
                    <el-table-column v-if="type==='doing'"
                                     prop="normalIsFinish"
                                     width="150"
                                     align="center"
                                     label="非质保金收付款情况">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.normalIsFinish === 1"
                                    type="success">已完成</el-tag>
                            <el-tag v-else-if="scope.row.normalIsFinish === 0"
                                    type="danger">未完成</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="contractProjectName"
                                     width="200"
                                     label="关联项目">
                    </el-table-column>
                    <el-table-column prop="deptName"
                                     width="140"
                                     label="部门">
                    </el-table-column>
                    <el-table-column prop="chargeUserName"
                                     width="100"
                                     align="center"
                                     label="负责人">
                    </el-table-column>

                    <el-table-column v-if="type==='begin'"
                                     prop="createTime"
                                     width="200"
                                     label="新增日期">
                    </el-table-column>
                    <el-table-column v-if="type==='begin'"
                                     prop="updateTime"
                                     width="200"
                                     label="最近修改日期">
                    </el-table-column>

                    <el-table-column v-if="type==='doing'"
                                     prop="nextRemindTime"
                                     width="140"
                                     label="下一次收付款日期">
                    </el-table-column>
                    <el-table-column v-if="type==='doing' || type==='undo'"
                                     prop="startTime"
                                     width="110"
                                     label="合同开始日期">
                    </el-table-column>
                    <el-table-column v-if="type==='doing' || type==='undo'"
                                     prop="endTime"
                                     width="110"
                                     label="合同结束日期">
                    </el-table-column>
                    <el-table-column v-if="type==='undo'"
                                     prop="finishStatus"
                                     width="100"
                                     label="状态">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.finishStatus === 'FINISH'"
                                    type="success">正常</el-tag>
                            <el-tag v-else-if="scope.row.finishStatus === 'EXCEPTION'"
                                    type="danger">异常</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="memo"
                                     width="200"
                                     label="备注">
                    </el-table-column>

                    <el-table-column label="操作"
                                     fixed="right"
                                     width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row,{isSeeDetail:true})">合同详情</el-link>
                            <!-- <el-link type="danger"
                                     v-if="type==='begin'"
                                     @click="editTable(scope.row)">编辑</el-link> -->
                            <el-popconfirm confirmButtonText='确认'
                                           cancelButtonText='取消'
                                           icon="el-icon-info"
                                           iconColor="red"
                                           @confirm="deleteTable(scope.row)"
                                           title="是否删除当前合同，删除后合同数据将无法找回？">
                                <el-link slot="reference"
                                         v-if="type==='begin' && commonJs.isShowBtn(detailRole, 'delete_contract')"
                                         type='danger'>删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
        <supplier-info-dialog v-if="dialogVisible"
                              :formData="rowData"
                              @close="closeSystemDialog" />

        <popAddCompanyAccount v-if="companyAccountVisible"
                              @close="closeCompany"></popAddCompanyAccount>
    </div>
</template>

<script>
import contractManageApi from "@/api/contractManageApi.js";
import commonAPI from "@/api/commonAPI.js";
import Pagination from "@/components/Pagination"; // 分页
import deptSelectTree from "@/components/selectTree/deptSelectTree"; //部门下拉树
// import customerOrProviderSelectTree from "@/components/selectTree/customerOrProviderSelectTree"; //客户/供应商下拉树
import selectTree from "@/components/selectTree/custSupChoose"; //客户/供应商选择
import popAddCompanyAccount from "./contractManage/components/popAddCompanyAccount"; //客户/供应商选择
export default {
    name: "contractManageList",
    props: {
        type: {
            type: String,
            default: "",
        },

        //合同状态
        contractStatus: {
            type: String,
            default: "",
        },

        /* 数据权限 */
        detailRole: {},
    },

    watch: {
        // roleInfo(val) {
        //     this.detailRole = this.commonJs.tabIsAlive(
        //         val,
        //         "合同管理列表"
        //     );
        // },
    },

    components: {
        Pagination,
        deptSelectTree,
        selectTree,
        popAddCompanyAccount,
    },

    data() {
        return {
            dialogVisible: false,
            companyAccountVisible: false, //公司账户管理
            searchForm: {
                name: "", //合同名称
                companyId: "", //客户或供应商id
                type: "", //合同类型
                deptId: "", //部门id
                page: 1,
                size: 20,
            },
            // detailRole: {}, //权限数据
            options: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "PAY_CONTRACT",
                    label: "支出",
                },
                {
                    value: "INCOME_CONTRACT",
                    label: "收入",
                },
            ],

            quality: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "已完成",
                },
                {
                    value: "0",
                    label: "未完成",
                },
            ],
            treeData: [],
            needClean: false, //清空部门
            cleanProvider: false, //清空客户供应商
            pageTotal: 0,
            isloading: false,
            iscreating: false, //创建合同编号
            rowData: null,
            tableData: [],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            contractManageApi
                .getContractTable(
                    Object.assign(searchForm, { status: [this.contractStatus] })
                )
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                    this.$emit("contractTypeNum", {
                        contractStatus: this.contractStatus,
                        num: this.pageTotal,
                    }); //设置总数
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //如果是新增 生成合同id
        getUniqueId() {
            this.iscreating = true;
            commonAPI
                .getUniqueId()
                .then((res) => {
                    this.iscreating = false;
                    this.goDetrail({
                        data: JSON.stringify(
                            Object.assign({
                                listType: this.type,
                                isAdd: true,
                                id: res.content,
                            })
                        ),
                    });
                })
                .catch(() => {
                    this.iscreating = false;
                });
        },

        //查看详情
        details(e, edit) {
            window.localStorage.setItem(
                "contractManageTab",
                this.contractStatus
            );
            this.goDetrail({
                data: JSON.stringify(
                    Object.assign(
                        {},
                        e,
                        { listType: this.type, id: e.id },
                        edit || {}
                    )
                ),
            });
        },

        //前往详情页
        goDetrail(data) {
            this.$router.push({
                path: "/contractManage/contractManageDetail",
                query: data,
            });
        },
        // 搜索
        search() {
            this.defaultSearch();
        },

        //清空筛选条件
        celanSearch() {
            this.searchForm.name = "";
            this.searchForm.companyId = "";
            this.searchForm.type = "";
            this.searchForm.deptId = "";
            this.searchForm.status = "";
            this.searchForm.normalIsFinish = "";
            this.searchForm.date = null;
            this.needClean = Math.random();
            this.cleanProvider = Math.random();
            this.getList();
        },

        // 新增
        addNew() {
            this.$store.commit("setContractMoney", 0);
            this.getUniqueId();
        },

        //打开公司账户
        openCompanyAccount() {
            this.companyAccountVisible = true;
        },

        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.dialogVisible = false;
            this.defaultSearch();
        },

        //关闭公司信息弹框
        closeCompany() {
            this.companyAccountVisible = false;
        },

        // 默认查询第一页
        defaultSearch() {
            this.searchForm.page = 1;
            this.getList();
        },

        // 表格的編輯操作
        editTable(e) {
            this.rowData = e;
            this.dialogVisible = true;
        },
        // 表格的删除操作
        deleteTable(row) {
            this.isloading = true;
            contractManageApi
                .deleteContractTable(row.id)
                .then(() => {
                    this.isloading = false;
                    this.$message({
                        type: "success",
                        message: "删除成功",
                    });
                    this.getList();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //部门的选择
        departmentClick(node) {
            this.searchForm.deptId = node.id;
        },

        //部门清空
        clearDept() {
            this.searchForm.deptId = "";
        },

        //客户选择
        customerClick(node) {
            this.searchForm.companyId = node.id;
        },

        //客户清空
        clearProvider() {
            this.searchForm.companyId = "";
        },
    },

    /* 组件缓存触发 */
    activated(){
        this.getList();
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 270 });
        },

        // //权限数据
        // roleInfo() {
        //     return this.$store.state.role.roleInfo;
        // },
    },
};
</script>

<style lang='scss' scoped>
.width150 {
    width: 150px !important;
}
</style>
